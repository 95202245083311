/// Colors ///

//Light theme
$bright-blue:hsl(220, 98%, 61%);
$very-light-grey:hsl(0, 0%, 98%);
$very-light-grayish-blue:hsl(236, 33%, 92%);
$light-grayish-blue:hsl(233, 11%, 84%);
$dark-grayish-blue:hsl(236, 9%, 61%);
$very-dark-grayish-blue:hsl(235, 19%, 35%);

//Dark theme
$very-dark-blue:rgb(22, 23, 34);
$very-dark-desaturated-blue:hsl(235, 24%, 19%);
$light-grayish-blue-dark:hsl(234, 39%, 85%);
$light-grayish-blue-dark-hover:hsl(236, 33%, 92%);
$dark-grayish-blue-dark:hsl(234, 11%, 52%);
$very-dark-grayish-blue-dark:hsl(233, 14%, 35%);
$hm:hsl(237, 14%, 26%);

body,html,input,button{
    font-size: 18px;
    margin: 0px;
    font-family: 'Josefin Sans', sans-serif;
}


h1{
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 20px;
}

h1,h2,h3,h4,h5,h6,p,a,ul,li,label,input{
    margin: 0;
}
ul,li{
    padding: 0;
}

input,button{
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
}

ul{
    list-style-type: none;
}

/// Classes /// 
main{
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 0;
    .background-image-container{
        width: 100%;
        height: 300px;
        background-size: cover;
        background-position: center;
    }
    section{
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        .main-container{
            width: 100%;
            max-width: 540px;
            margin-top: 80px;
        }
        header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            h1{
                color:$very-light-grey;
            }
        }
        form{
            padding: 20px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            gap:20px;
            margin-bottom: 20px;
            margin-top: 25px;
            label{
                white-space: nowrap;
            }
            .todo-input{
                width: 100%;
                font-size: 18px;
            }
        }
        .main-checklist-container{
            -webkit-box-shadow: 0px 4px 61px 1px rgba(82,82,82,0.08);
            -moz-box-shadow: 0px 4px 61px 1px rgba(82,82,82,0.08);
            box-shadow: 0px 4px 61px 1px rgba(82,82,82,0.08);
          .footer-on-big-screen{
            padding: 15px 20px 15px 20px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            h4,button{
                font-size: 14px;
                font-weight: 400;
                transition: 0.3s ease;
            }
            ul{
                display: flex;
                gap:20px;
                li{
                    font-size: 14px;
                    font-weight: 700;
                    cursor: pointer;
                    transition: 0.2s ease;
                    
                }
                .selected{
                    color:$bright-blue !important;
                }
            }
          }
          .footer-on-small-screen{
            padding: 15px 20px 15px 20px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            display: none;
            flex-direction: column;
            .first-action-bar{
                display: flex;
                justify-content: space-between;
                h4,button{
                    font-size: 14px;
                    font-weight: 400;
                    transition: 0.3s ease;
                } 
            }
            .second-action-bar{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;
                ul{
                    display: flex;
                    gap:20px;
                    li{
                        font-size: 14px;
                        font-weight: 700;
                        cursor: pointer;
                        transition: 0.2s ease;
                        
                    }
                    .selected{
                        color:$bright-blue !important;
                    }
                }   
             }
          }
       }
    }
}

#dark{
    background-color: $very-dark-blue;
    .background-image-container{
        background-image: url('../../public/images/bg-desktop-dark.jpg');
    }
    form{
        background-color:$very-dark-desaturated-blue;
        .todo-input{
            color: $very-light-grey;
        }
        .todo-input::placeholder{
            color: $dark-grayish-blue-dark;
        }
    }
    .footer-on-big-screen, .footer-on-small-screen{
        background-color: $very-dark-desaturated-blue;
        *{
            color: $very-dark-grayish-blue-dark;
        }
        button:hover{
            color:$very-light-grey;
        }
        li:hover{
            color:$bright-blue;
        }
    }
}



#light{
    background-color: $very-light-grey;
    .background-image-container{
        background-image: url('../../public/images/bg-desktop-light.jpg');
    }
    form{
        background-color: $very-light-grey;
        .todo-input{
            color: $very-dark-grayish-blue;
        }
        .todo-input::placeholder{
            color: $dark-grayish-blue-dark;
        }
    }
    .footer-on-big-screen, .footer-on-small-screen{
        background-color: $very-light-grey;
        *{
            color: $dark-grayish-blue;
        }
        button:hover{
            color:$very-dark-grayish-blue;
        }
        li:hover{
            color:$very-dark-grayish-blue;
        }
    }
}

@media screen and (max-width:540px) {
    h1{
        letter-spacing: 15px !important;
        font-size: 28px !important;
    }
    p{
        font-size: 16px !important;
    }
    section{
        padding: 30px;
        form{
            input{
                font-size: 16px !important;
            }
        }
        .main-container{
            margin-top: 40px !important;
        }
    }
    .footer-on-big-screen{
        display: none !important;
    }
    .footer-on-small-screen{
        display: flex !important;
    }
}