@import url("../../styles/index.scss");

$gradient-fill:linear-gradient(152deg, rgba(110,186,248,1) 0%, rgba(170,121,246,1) 80%);

.custom-checkbox {
    -webkit-appearance: none; /* Remove default checkbox style for webkit browsers */
    -moz-appearance: none; /* Remove default checkbox style for Mozilla Firefox */
    appearance: none; /* Remove default checkbox style for other browsers */
    padding: 12px; /* Working as width and height */ 
    border-radius: 50%; /* Make the checkbox round */
    border: 1.5px solid hsl(233, 14%, 35%); /* Add a border to the checkbox */
    outline: none; /* Remove outline on focus */
    position: relative;
    box-sizing: border-box;
}

.custom-checkbox:hover:disabled{
    border-image:inherit !important;
    border-image-slice: inherit !important;
}

/* Style the checkbox when checked */
.custom-checkbox:checked{
    position: relative;
    background: $gradient-fill;
}

.custom-checkbox::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./../../../public/images/icon-check.svg');
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
}

.custom-checkbox:checked::before{
    transform: scale(1); 
}
